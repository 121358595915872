// Variable overrides
// Sidebar

$sidebar-bg:                          #009b3e;

// Sidebar Header

$sidebar-header-bg:                   rgba(0,0,0,.2);

// Sidebar Form

$sidebar-form-bg:                     darken($sidebar-bg,10%);
$sidebar-form-color:                  #fff;
$sidebar-form-placeholder-color:      rgba(255,255,255,.7);

// Sidebar Navigation

$sidebar-nav-color:                   #fff;
$sidebar-nav-title-color:             #fff;
$sidebar-nav-link-color:              #fff;
$sidebar-nav-link-bg:                 transparent;
$sidebar-nav-link-icon-color:         #fff;

$sidebar-nav-link-hover-color:        rgba(255, 255, 255, 0.767);
$sidebar-nav-link-hover-bg:           theme-color("primary");
$sidebar-nav-link-hover-icon-color:   rgba(255, 255, 255, 0.767);

$sidebar-nav-link-active-color:       #fff;
$sidebar-nav-link-active-bg:          darken($sidebar-bg, 5%);
$sidebar-nav-link-active-icon-color:  theme-color("primary");

$sidebar-nav-link-disabled-color:       darken(#fff, 30%);
$sidebar-nav-link-disabled-bg:          $sidebar-bg;
$sidebar-nav-link-disabled-icon-color:  $sidebar-nav-link-icon-color;

$sidebar-nav-dropdown-color:          #fff;
$sidebar-nav-dropdown-bg:             $sidebar-bg; //darken($sidebar-bg, 2%);
$sidebar-nav-dropdown-indicator-color:#fff;
$sidebar-nav-dropdown-indicator-hover-color:$sidebar-nav-link-hover-color;