@media (max-width: 600px) {
  table.sv-table {
    display: table !important;
    thead {
      display: table-header-group !important;
    }
    tr {
      display: table-row !important;
    }
    td {
      display: table-cell !important;
      text-align: center !important;
      &:first-child {
        text-align: left !important;
      }
    }
    td:after {
      content: none !important;
    }
    tbody {
      display: table-row-group !important;
    }
  }
}

.sv-main {
  font-size: 16px;
}

.sv-title {
  font-family: initial !important;
}

.sv-container-modern {
  background-color: #fafafa;
}

.sv-table {
  font-family: inherit !important;
}

.sv-container-modern__title {
  padding-top: 2em !important;
}

.sv-root-modern input.sv-text,
textarea.sv-comment,
select.sv-dropdown {
  background-color: white !important;
}

.sv-matrix__cell {
  min-width: 0 !important;
}

.sv-table__cell {
  padding: 8px !important;
}

table.sv-matrixdropdown td:before {
  content: none !important;
}

.sv-body.sv-completedpage {
  display: none !important;
}

.sv-root-modern .sv-radio--allowhover:hover .sv-radio__svg {
  fill: none !important;
}

.sv-root-modern .sv-checkbox--allowhover:hover .sv-checkbox__svg {
  fill: none !important;
  background-color: inherit !important;
  border: 3px solid rgba(64, 64, 64, 0.5);
}

.sv-root-modern .sv-rating__item--selected .sv-rating__item-text:hover {
  background-color: rgb(26, 179, 148) !important;
  color: rgb(255, 255, 255) !important;
}

.sv-root-modern .sv-rating__item-text:hover {
  background-color: inherit !important;
  color: rgb(159, 159, 159) !important;
}


// Unset style of disabled elements in display mode

.sv-question--disabled .sv-question__header {
  color: unset !important;
}

.sv-root-modern .sv-checkbox--checked.sv-checkbox--disabled .sv-checkbox__svg {
  background-color: rgb(26, 179, 148) !important;
}